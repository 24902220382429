import styled from 'styled-components';

export default styled.section`
  &.register-bg {
    background: #343643;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
  }
  .register-area {
    margin: 0px auto;
    padding-top: 90px;
    padding-bottom: 90px;
    @media screen and (max-width: 768px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    p.register-title {
      font-family: Manufaktur-Bold;
      font-style: normal;
      font-weight: 800;
      font-size: 44px;
      line-height: 96%;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #ffffff;
      @media screen and (max-width: 768px) {
        font-size: 40px;
      }
    }

    p.subtitle {
      font-size: 22.5px;
      max-width: 770px;
      margin-top: 44px !important;
      margin: 0 auto;
    }

    .register-panel {
      margin-top: 36px;
      margin-left: 15px;
      margin-right: 15px;
      max-width: 370px;
      min-width: 370px;
      min-height: 230px;
      display: inline-block;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 24px 32px;
      position: static;
      left: 0px;
      top: 0px;
      border: 2px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      background-color: rgba(0, 0, 0, 0.4);
      @media screen and (max-width: 768px) {
        max-width: 100%;
        min-width: 100%;
        max-height: 176px;
        min-height: 176px;
        margin: 24px 0 0 0;
        .grouparow {
          display: none;
        }
      }
      &:hover {
        border: 2px solid rgba(252, 106, 44, 0.9);
        background: rgba(252, 106, 44, 0.9);
        transition: 0.3s;
        .date {
          color: #ffffff;
        }
        .grouparow {
          opacity: 0.8;
        }
      }
      .location {
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        color: #ffffff;
      }
      .date {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fc6a2c;
        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
      .grouparow {
        margin-top: 27px;
        opacity: 0;
      }
      .more {
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #ffffff;
        position: absolute;
        bottom: 24px;
        width: 100%;
        text-align: center;
        left: 0px;
        img {
          margin-top: 2px;
          margin-left: 5px;
        }
      }
    }
    #demo-form {
      padding-top: 44px;
      max-width: 570px;
      margin: 0 auto;
      button {
        height: 42px;
        left: 302px;
        top: 172px;
        background: #fc6a2c;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        text-transform: uppercase;
      }
      input,
      select {
        background: transparent;
        font-size: 18px;
        height: 48px;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
        & + label {
          color: #fff;
        }
        &:focus {
          color: #fff;
          border-color: #fc6a2c;
          outline: 0;
          box-shadow: none;
          & + label {
            color: #fff;
          }
        }
        &.light-input {
          background-color: transparent;
          color: #fff;
          border-color: #fff;
          &:focus {
            color: #fff;
            border-color: #fff;
          }
        }
      }
      .form-group {
        position: relative;
        margin: 0 0 18px;
        padding: 0 !important;
        .form-label.material-label {
          position: absolute;
          z-index: 0;
          top: 11px;
          left: 8px;
          transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          transform-origin: left top;
          pointer-events: none;
          font-size: 16px;
          padding: 0 8px;
        }
        .form-control {
          border-radius: 3px;
          &.btr-0 {
            border-top-right-radius: 0 !important;
          }
          &.btl-0 {
            border-top-left-radius: 0 !important;
          }
          &.bbr-0 {
            border-bottom-right-radius: 0 !important;
          }
          &.bbl-0 {
            border-bottom-left-radius: 0 !important;
          }
          padding: 0.5rem 0.88rem;
          height: 47px;
          &:focus {
            box-shadow: none !important;
          }
          &:valid + .material-label,
          &:focus + .material-label,
          & + .material-label.filled {
            transform: translate(0, -22px) scale(0.75);
            background: #343643;
            padding: 2px 10px;
            z-index: 20;
            &.light-label {
              background: var(--primary-color);
              color: #fff;
            }
          }
        }
        .invalid-feedback {
          position: absolute;
          top: 100%;
          left: 0;
        }
      }
      .was-validated .form-control:invalid,
      .form-control.is-invalid {
        & + .material-label {
          color: #dc3545;
        }
      }
      .form-select {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 0;
          top: 22px;
          right: 18px;
          border: 4px solid transparent;
          border-top-color: #425466;
        }
        select {
          appearance: none;
          background-color: transparent;
          background-position: right 24px center !important;
        }
      }
      label {
        font-size: 16px;
        margin-bottom: 12px;
        &.required {
          &::after {
            content: '*';
          }
        }
      }
      .was-validated .form-control:valid,
      .form-control.is-valid {
        border-color: #1c3fff;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231C3FFF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        & + label {
          color: #1c3fff;
        }
      }
      .was-validated .form-control:valid:focus,
      .form-control.is-valid:focus {
        box-shadow: 0 0 0 0.2rem rgb(28 59 255 / 25%);
      }
      button {
        text-transform: uppercase;
        margin-top: 8px;
        height: 48px;
      }
      .MuiSelect-selectMenu {
        min-height: 11px;
        padding: 18px 14px;
      }
      .MuiSvgIcon-root {
        font-size: 24px;
      }
      .MuiInputLabel-outlined {
        transform: translate(16px, 14px) scale(1);
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: #fff;
      }
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #ced4da;
      }
      .MuiOutlinedInput-notchedOutline span {
        display: none;
      }
      .MuiSelect-outlined.MuiSelect-outlined {
        color: #fff;
        font-size: 18px;
        text-align: left;
      }
      .MuiFormLabel-root {
        color: #fff;
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background: #343643;
        color: #343643;
        padding-right: 6px;
        display: none;
      }
      .MuiSelect-select:focus {
        background-color: transparent;
      }
      .MuiSelect-icon {
        color: #fff;
      }
    }
    .PrivateNotchedOutline-legendLabelled-3 {
      font-size: 1.25em !important;
      color: #fff;
    }

    .register-btn:hover,
    .register-btn:active,
    .register-btn:focus {
      background-color: #f05e1f !important;
      border-color: #f05e1f;
    }

    .link-wrapper {
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
`;
